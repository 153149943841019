body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    margin: 0;
    background-color: #000000;
    color: #ffffff;
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
}

h1 {
    font-size: 3rem
}

h2 {
    font-size: 2.5rem;
}

h3 {
    font-size: 2.2rem;
}
